<template>
  <v-content>
    <v-container>
      <v-row justify="center">
        <v-col sm="8" class="mt-5">
          <v-card class="pa-5">
            <v-row>
              <v-col class="mx-auto" align="center" sm="10">
                <img src="../../assets/identity-logo.png" height="64px" />
                <h4 style="height:100%; vertical-align:middle;"
                  >This application uses the Wyoming Identity System for
                  authentication if you already have an account with the
                  Identity System leave the password field blank when
                  registering to link your accounts.</h4
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="First Name"
                  v-model="user.FirstName"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="Last Name"
                  v-model="user.LastName"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="Email"
                  v-model="user.Email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <span
                  >If the email you have enter is a Google email, you may sign
                  in without a password by click 'Sign In With Google' when you
                  log in, if it is not a Google email or you would prefer to
                  sign in with a password, type a password below. Otherwise
                  leave this field blank.</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="Password"
                  v-model="user.Password"
                  required
                  hint="Passwords must be at least 13 characters long, contain upper and lowercase letters, have at last one number, and one special character"
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-2">
              <v-col align="center">
                <v-btn
                  class="ma-2"
                  color="primary"
                  @click="register"
                  :disabled="disableReg"
                  >Register</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="850px">
        <v-card>
          <v-card-text>
            {{ msg }}
            <div v-for="(e, idx) in errors" :key="idx">
              {{ e }}
              <br />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const moduleNamespace = 'user'
export default {
  page: {
    title: 'Register',
  },
  name: 'Register',
  data() {
    return {
      user: {},
      dialog: false,
      msg: '',
      errors: [],
      loading: false,
    }
  },
  methods: {
    register() {
      this.user.UserName = this.user.Email
      this.loading = true
      axios
        .post(`Register`, this.user)
        .then((response) => {
          this.user = {}
          this.msg = ''
          this.errors = []
          this.msg = `You have been successfully registered: ${response.data}`
          this.dialog = true
          this.loading = false
        })
        .catch((e) => {
          this.msg = ''
          this.errors = []
          let err = e.response.data
          if (JSON.stringify(err).substring(0, 1) != '{') {
            this.errors.push(err)
          } else {
            Object.keys(err).forEach((val) => {
              this.errors.push(err[val][0])
            })
          }
          this.msg = `There was a problem completing your registration: `
          this.dialog = true
          this.loading = false
        })
    },
  },
  computed: {
    disableReg() {
      if (
        this.user.Email &&
        this.user.FirstName &&
        this.user.LastName &&
        !this.loading
      ) {
        return false
      }
      return true
    },
  },
  watch: {},
  components: {},
}
</script>
<style></style>
